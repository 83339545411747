import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-0`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-4 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;


  const TextCenter = styled.div`
  ${tw`text-lg  text-gray-800 w-full`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-0`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-0 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;



export default () => {

  var x = "Membangun ekosistem bisnis retail melalui program kemitraan yang melibatkan komunitas umat."
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Tentang DRISS</Subheading>}
        heading="Distribution Retail Integration System Solutions"
        description="5 buah pilar bisnis kami yaitu retail dengan segala seluk beluknya terkait dengan distribusi bahan, bagaimana sebuah proses integrasi-nya, system yang digunakan dan membungkus menjadi sebuah solusi. Bagaimana menggabungkan 5 buah pilar tersebut, disitulah kami ada untuk membuat bisnis anda menjadi lebih baik."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://source.unsplash.com/MRtp7iCgLVw/600x300"
      />
      <MainFeature1
        style={{ whiteSpace: "pre-line" }}
        subheading={<Subheading>Visi dan Misi</Subheading>}
        heading="Menjadi Lebih Baik"
        buttonRounded={false}
        description=
          {<Text>
            <ol>
              <li>Membangun ekosistem bisnis retail melalui program kemitraan yang melibatkan komunitas umat.</li>
              <li>Mengembangkan jaringan bisnis retail dengan metode pendampingan yang terintegrasi sistem</li>
              <li>Memberikan manfaat kepada stakeholder khususnya dan umat pada umumnya</li>
            </ol>
          </Text>}
        primaryButtonText="Hubungi Kami"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nilai-nilai Kami</Subheading>}
        heading="Kekuatan Kami Adalah"
        description=
        {<TextCenter>
          <ol>
            <li>Pengalaman dibisnis modern retail serta food and beverages</li>
            <li>Membangun jaringan ekonomi keumatan di bisnis modern retail</li>
            <li>Memberikan manfaat kepada stakeholder khususnya dan umat pada umumnya</li>
          </ol>
        </TextCenter>}

        // cards={[
        //   {
        //     imageSrc: SupportIconImage,
        //     title: "24/7 Support",
        //     description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
        //   },
        //   {
        //     imageSrc: ShieldIconImage,
        //     title: "Strong Teams",
        //     description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
        //   },
        //   {
        //     imageSrc: CustomerLoveIconImage,
        //     title: "Customer Satisfaction",
        //     description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
        //   },
        // ]}
        // linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Tim Kami</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
