import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import odoo17 from "images/odoo_apps17.png";
import sale1 from "images/sale2.png";
import so1 from "images/so-1.png";
import acct from "images/acct.png";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;


    // class="mb-4 mt-0 text-base font-light leading-relaxed"
    // ${tw`mt-0 leading-loose`}

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mb-4 mt-0 text-base font-light leading-relaxed`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`ml-3 list-disc list-outside`}
    li {
      ${tw`ml-3 mb-0 list-decimal`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "Contoh Kustomisasi Odoo dengan Mobile Application Flutter" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p >Last updated: Mei 4, 2020</p>
            <h2>Contoh Kustomisasi Odoo dengan Mobile Application Flutter</h2>
            <br />
            <p>
            Jika Anda ingin melakukan kustomisasi Odoo dengan menggunakan aplikasi mobile yang dikembangkan dengan framework Flutter, berikut adalah beberapa contoh kustomisasi yang dapat Anda pertimbangkan:            </p>
            {/* <p>Odoo Enterprise adalah versi berbayar dari perangkat lunak perusahaan sumber terbuka yang disebut Odoo. Odoo sendiri adalah suatu platform perangkat lunak yang menyediakan berbagai modul bisnis, seperti manajemen keuangan, penjualan, manufaktur, sumber daya manusia, dan banyak lagi. Platform ini dirancang untuk membantu perusahaan mengelola berbagai aspek operasional mereka.</p>
            <p>Berbeda dengan versi sumber terbuka (Odoo Community), Odoo Enterprise menawarkan sejumlah fitur tambahan dan dukungan yang lebih luas. Fitur-fitur tersebut dapat mencakup modul-modul yang lebih canggih, dukungan teknis yang lebih baik, integrasi pihak ketiga yang lebih kuat, dan keamanan yang ditingkatkan. Pengguna Odoo Enterprise biasanya membayar lisensi berlangganan untuk mendapatkan akses ke fitur-fitur premium ini.</p>
            <p>Odoo sendiri awalnya dikenal sebagai OpenERP (Enterprise Resource Planning) sebelum mengadopsi merek Odoo. Platform ini mencoba menyediakan solusi terintegrasi untuk kebutuhan manajemen bisnis, memungkinkan perusahaan untuk mengotomatiskan proses bisnis mereka dan meningkatkan efisiensi.</p> */}
            {/* <h2>Fitur odoo accounting</h2> */}
            <br />
            <ol>
              <li>Antarmuka Pengguna (UI/UX)<br />
              <p>
              Menyesuaikan tata letak dan desain antarmuka pengguna (UI/UX) agar sesuai dengan branding perusahaan atau preferensi desain.
Menambahkan elemen-elemen baru atau mengubah gaya tata letak agar sesuai dengan kebutuhan aplikasi mobile.
              </p>
              </li>
              <li>Fungsi Push Notification<br />
              <p>Menambahkan atau menghapus bidang (field) pada formulir Pembelian untuk mencakup informasi tambahan yang dibutuhkan.
Menyesuaikan tata letak dan desain formulir sesuai dengan preferensi perusahaan.
</p>
              </li>
              <li>Integrasi dengan Kamera<br />
              <p>Menambahkan kemampuan untuk mengambil foto atau memindai barcode menggunakan kamera perangkat untuk mempermudah proses, misalnya, dalam penerimaan barang.</p>
              </li>
              <li>Sinkronisasi Data Offline<br />
              <p>Menambahkan kemampuan untuk menyimpan dan menyinkronkan data secara offline, memungkinkan pengguna untuk bekerja tanpa koneksi internet dan kemudian menyinkronkan perubahan saat kembali online.
              </p>
              </li>
              <li>Widget dan Komponen Kustom<br />
              <p>Mengintegrasikan modul Pembelian dengan modul lain seperti Inventaris atau Keuangan untuk memastikan konsistensi data di seluruh platform Odoo.
Menambahkan logika bisnis tambahan yang membutuhkan sinkronisasi antar modul
              </p>
              </li>
              <li>Integrasi dengan API Eksternal<br />
              <p>MMengintegrasikan aplikasi mobile dengan layanan eksternal atau API pihak ketiga untuk memperoleh data tambahan atau menyediakan fungsionalitas tambahan.
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
              <li>Penggunaan Fitur Flutter<br />
              <p>Memanfaatkan fitur-fitur khusus Flutter, seperti animasi yang kaya, untuk meningkatkan pengalaman pengguna
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
              <li>Keamanan Tambahan<br />
              <p>Menambahkan lapisan keamanan tambahan, seperti autentikasi dua faktor atau enkripsi data, untuk meningkatkan tingkat keamanan aplikasi mobile.
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
              <li>Pembaruan Real-Time<br />
              <p>Mengimplementasikan pembaruan real-time untuk memberikan respons cepat terhadap perubahan data, seperti pembaruan stok atau status pesanan.
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
              <li>Riwayat Aktivitas Pengguna<br />
              <p>Menambahkan fungsi untuk mencatat dan melacak aktivitas pengguna, memberikan kemampuan audit dan pemantauan yang lebih baik.
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
            </ol>
            <p>
            Sebelum memulai kustomisasi, pastikan Anda memahami dokumentasi resmi Odoo dan Flutter, serta melakukan uji coba yang cukup untuk memastikan bahwa kustomisasi yang Anda lakukan tidak merusak integritas sistem dan aplikasi.
            </p>
            <p>Jika ingin lebih mengenal odoo silakan hubungi kami by whatsapp</p>

            {/* <h3>Sesi  2 : Memahami Pengertian retail </h3>
            <p>
            Memahami Fungsi dan jenis jenis perusahaan Retail            </p>
            <h3>Sesi  3 : Memahami matematika retail  </h3>
            <p>
            Akan menjabarkan konsep Matematika retail yang umum digunakan dalam bisnis retail             </p>


            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p> */}
            <br />
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6285781574709&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
