import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import blogpostimg from "images/sddefault.jpeg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Blog Posts",
  posts = [
    {
      imageSrc: 
        "https://media.licdn.com/dms/image/D4E12AQFVzZcQ2PDdcA/article-cover_image-shrink_720_1280/0/1692609899064?e=2147483647&v=beta&t=mlBOAEUgbXkppYZ_TZRLqZ_A6WTiifqKpFOwNN6-rDY",
      category: "Odoo ERP",
      date: "April 21, 2020",
      title: "Mengetahui apa itu Odoo ERP",
      description:
        "Buat yang ingin mempelajari Odoo ERP dari sisi kemampuan dan Apa yg ditawarkan",
      url: "/bl001",
      featured: true
    },
    // {
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
    //   category: "Odoo ERP",
    //   date: "April 21, 2020",
    //   title: "Mengetahui apa itu Odoo ERP",
    //   description:
    //     "Buat yang ingin mempelajari Odoo ERP dari sisi kemampuan dan Apa yg ditawarkan",
    //   url: "/bl001",
    //   featured: true
    // },    
    getPlaceholderPost(),
    getPlaceholderPost2(),
    getPlaceholderPost3(),
    getPlaceholderPost4(),
    getPlaceholderPost5(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost(),
    // getPlaceholderPost()
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://cdn.discordapp.com/attachments/938645865592160308/1195595025166372985/sales_banner.png?ex=65b48fa3&is=65a21aa3&hm=1eb7e227428faadb4ca1336a73bde7e5d989b989c1096871c92b0dc1ae0dc134&",
  category: "Odoo ERP",
  date: "April 29, 2020",
  title: "Pengenalan modul Sales",
  description:
    "Module sales atau penjualan adalah salah satu modul penting yang banyak dipakai oleh sebuah usaha untuk menangkap proses sales",
  url: "/bl002"
});

const getPlaceholderPost2 = () => ({
  imageSrc:
    "https://cdn.discordapp.com/attachments/938645865592160308/1195599947500900362/accounting.png?ex=65b49438&is=65a21f38&hm=4edf3c4ac44c5aa906554f3f40d5562ae18282a9b4fb63b9271613beca293cce&",
  category: "Odoo ERP",
  date: "April 30, 2020",
  title: "Pengenalan modul Akunting",
  description:
    "Module Accounting merupakan salah satu tools yang menurut kami adalah salah satu keunggulan odoo dengan aplikasi lainnya,dikarenakan mudah dimengerti dan gampang untuk customize",
  url: "/bl003"
});

const getPlaceholderPost3 = () => ({
  imageSrc:
    "https://cdn.discordapp.com/attachments/938644537671311393/1196199010474668162/jason-goodman-vbxyFxlgpjM-unsplash.jpeg?ex=65b6c224&is=65a44d24&hm=064a6c2f3d7d840b9507e50a6c12faf5906d46991ebbbc1628a75a40ad764629&",
  category: "Odoo ERP",
  date: "May 1, 2020",
  title: "Bagaimana Proses Customization di Odoo",
  description:
    "Pertimbangan kenapa perusahaan memilih odoo sebagai application platform dalam pengembangan bisnis usahanya",
  url: "/bl004"
});

const getPlaceholderPost4 = () => ({
  imageSrc:
    "https://cdn.discordapp.com/attachments/938644537671311393/1196203759144026222/austin-distel-744oGeqpxPQ-unsplash.jpeg?ex=65b6c690&is=65a45190&hm=fe7f060065dff341003a459f378a7eeb9073f8723a7d7ef07b2c1ccb433cf7d5&",
  category: "Odoo ERP",
  date: "May 2, 2020",
  title: "Contoh Kustomisasi PO di Odoo",
  description:
    "Beberapa contoh dari hasil pengalaman kami tentant customization di Module Purchase Order",
  url: "/bl005"
});

const getPlaceholderPost5 = () => ({
  imageSrc:
    "https://cdn.discordapp.com/attachments/938644537671311393/1196209106814517248/nordwood-themes-q8U1YgBaRQk-unsplash.jpeg?ex=65b6cb8b&is=65a4568b&hm=1034839723c217d65a86a50bfa4e360557dfe90eaf83f3f50b458abcc78195a8&",
  category: "Odoo ERP",
  date: "May 4, 2020",
  title: "Contoh Kustomisasi Odoo dengan Mobile Application Flutter",
  description:
    "Bagaimana sebuah teknologi flutter di hubungkan dengan odoo",
  url: "/bl006"
});

