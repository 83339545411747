import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import odoo17 from "images/odoo_apps17.png";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mb-4 mt-0 text-base font-light leading-relaxed`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "Mengenal apa itu Odoo ERP" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2020</p>
            <h2>Sebuah pengantar singkat</h2>
            <br />
            <p>
            ERP adalah singkatan dari Enterprise Resource Planning sebuah aplikasi yang mempunyai keunggulan dalam mengkolaborasikan berbagai macam bisnis proses yang ada dalam 1 buah aplikasi. Tadinya pada saat kami diawal mempelajari odoo sekitar tahun 2018, kami menganggap bahwa ini aplikasi keungan biasa saja. Akan tetapi setelah kami mempelajari dan mencoba sendiri aplikasi ini, maka kami mengambil kesimpulan bahwa odoo ini adalah apllication framework. 
            </p>
            <p>
            Apa yang dimaksud dengan application framework, gambarannya adalah odoo sudah mempunyai beberapa standard module seperti sales, purchasing, inventory, accounting akan tetapi dari sisi kemudahan customization dan integrasinya itu kami menilai odoo akan menjadi sebuah platform masa depan yang bisa dikembangkan menyesuaikan kebutuhan operasional. Dalam implementasi-nya kami seperti biasa harus terlebih dahulu membuat assessment terlebih dahulu untuk bisnis proses yang customer inginkan.            
            </p>
            <p>Berangkat dari keinginan dan harapan customer terhadap proses bisnis yang sudah ada atau yang belum ada tentunya mengakibatkan hal-hal yang sudah ada dalam odoo bisa jadi belum sesuai dengan yang diharapkan sehingga perlu penyesuaian. Penyesuaian ini yang disebut sebagai customization dan ini yang kami sebut kemudahan didalam odoo. Berdasarkan pengalaman kami dalam mengelola aplikasi, membangun atau melakukan customization dengan menggunakan teknologi atau aplikasi yang lain kami beranggapan bahwa proses customization odoo sangat mudah.</p>
            <h2>Beberapa modul standard odoo</h2>
            <br />
            <ol>
              <li>Sales</li>
              <li>Purchasing</li>
              <li>Inventory</li>
              <li>Manufacture</li>
              <li>Accounting</li>
            </ol>
            <br />
            <p>
            Dan masih banyak modul-module standard odoo
            <img src={odoo17}></img>
            </p>
            <br />
            <p>Jika ingin lebih mengenal odoo silakan hubungi kami by whatsapp</p>

            {/* <h3>Sesi  2 : Memahami Pengertian retail </h3>
            <p>
            Memahami Fungsi dan jenis jenis perusahaan Retail            </p>
            <h3>Sesi  3 : Memahami matematika retail  </h3>
            <p>
            Akan menjabarkan konsep Matematika retail yang umum digunakan dalam bisnis retail             </p>


            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p> */}
            <br />
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6285781574709&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
