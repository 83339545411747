import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "RB-002 Product Management" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2022</p>
            <h2>Tujuan :</h2>
            <ol>
              <li>Memahami Pentingnya pengelolaan barang dagang di retail</li>
              <li>Memahami pengelolaan barang display</li>
              <li>MMemahami Pengelolaan basic penyimpanan Barang /Gudang</li>
              <li>Memahami resiko kehilangan Barang dan pencegahannya</li>
            </ol>
            <h2>Durasi : </h2>1 hari
            <h2>Requirement : </h2><p>Pengalaman di bisnis retail min 6 bulan</p>
            <h2>Investasi :</h2><p>Rp 750.000</p>
            <h3>Sesi  1 : Memahami Pentingnya Pengelolaan barang dagang di retail</h3>
            <p>
            Sesi pertama akan memberikan pemahaman tentang pentingnya pengelolaan barang [ada bisnis retail , akan dari mulai hulu sampai hilir :
            <ol>
              <li>Pada saat pembelian</li>
              <li>Pada saat penerimaan</li>
              <li>Pada saat penyimpanan</li>
              <li>Pada saat penjualan</li>
            </ol>
            
            </p>
            <h3>Sesi  2 : Memahami pengelolaan penyimpanan barang di gudang dan di rak display</h3>
            <p>
            Akan mendetailkan tip tips yang bisa dilakukan hasil pengalaman di bisnis retail yang dapat mensupport pencapaian keuntungan yang diharapkan</p>
            <h3>Sesi  3 : Memahami resiko kehilangan barang dan pencegahannya di retail </h3>
            <p>
            Akan menjabarkan kejadian yang umum terjadi di bisnis retail mengenai kehilangan barang dan KIat alternatif  yang bisa dilakukan utk menekan resiko tersebut</p>


            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
