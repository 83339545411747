import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImageForConsulting";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/MiniCenteredFooter";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const VerticalSpacer = tw.div`mt-10 w-full`



const HighlightedText = tw.span`text-red-500`



export default ({cards = null}) => {
    const defaultCards = [
        {
          imageSrc: ShieldIconImage,
          title: "Secure",
          description: "We strictly only deal with vendors that provide top notch security."
        },
        { imageSrc: SupportIconImage, title: "24/7 Support" },
        { imageSrc: CustomizeIconImage, title: "Customizable" },
        { imageSrc: ReliableIconImage, title: "Reliable" },
        { imageSrc: FastIconImage, title: "Fast" },
        { imageSrc: SimpleIconImage, title: "Easy" }
        ];

    if (!cards) cards = defaultCards;
    return (

        
        <AnimationRevealPage>
        <Hero />
        {/* <FeatureStats/> */}
        <Features 
            heading={<>Solusi <HighlightedText>Retail</HighlightedText></>}
            description="Konsep retail dari mulai feasiblity study, design layout, pemilihan barang dan segmentasi market serta banyak hal lainnya termasuk pemilihan system adalah kurang lebih solusi yang kami tawarkan dalam layanan konsultasi serta implementasinya."
        />
        {/* <MainFeature
            description="Konsep retail mini market, mulai dari tahapan assement , feasibility study"
        /> */}
        {/* <Testimonial 
            heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
        />
        <Pricing 
            heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
        />
        <FAQ
            heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
        />
        <Blog
            subheading="Blog"
            heading={<>We love <HighlightedText>Writing</HighlightedText></>}
        /> */}
        {/* <GetStarted/> */}
        <Footer />
        </AnimationRevealPage>
    );
}
