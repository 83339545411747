import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";

import { HashRouter as Router, Route, Link } from 'react-router-dom';
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import {Link} from "react-scroll";
// import Event from "demos/EventLandingPage.js";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import { Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";

import Event from "demos/EventLandingPage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImageForLanding.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorderForTraining.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";

import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;

const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const SectionContainer = tw(ContentWithVerticalPadding)`mb-0 mt-0`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-100 opacity-25`;
// const SectionContainer = tw(ContentWithVerticalPadding)``;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(https://source.unsplash.com/Z3ownETsdNQ/1920x1080)
  // background-image: url("https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");

`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const HeadingHero = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-2xl lg:text-3xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const ContainerContactUs = tw.div`relative`;

const Heading = tw(SectionHeading)`mt-12 text-white text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`bg-purple-600 text-purple-600
hocus:bg-purple-800 text-gray-200 inline-block lg:ml-6 mt-6 lg:mt-0`


const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
// const TwoColumn = tw.div`pt-24 pb-32 px-2 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const HeadingContactUs = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-2xl lg:text-2xl xl:text-3xl font-black text-gray-600 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-purple-800 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

// export const PrimaryLink = tw(NavLink)`
//   lg:mx-0
//   px-8 py-3 rounded bg-green-500 text-white
//   hocus:bg-red-600 hocus:text-white focus:shadow-outline
//   border-b-0
// `;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

// const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-red-500 font-bold rounded shadow transition duration-300 hocus:bg-red-500 hocus:text-gray-100 focus:shadow-outline`;
const PrimaryAction = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-0 bg-gray-300 text-gray-800 hocus:bg-purple-800 hocus:text-white`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const PrimaryLinkForTraining = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-0 mb-0 text-lg text-purple-800`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;


const portfoliourl = "#portfolio"
const SubheadingContactUs = tw(SubheadingBase)`text-center md:text-left`;
const Heading2 = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const DescriptionContactUs = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`


export default ({
  subheading = "Hubungi kami",
  heading = <>Silakan hubungi kami <span tw="text-purple-800">Jika ada pertanyaan</span><wbr/> lebih lanjut.</>,
  description = "Silakan lempar pertanyaan melalui email resmi kami atau whatsapp ke nomer kami",
  submitButtonText = "Contact Me",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  linkText = "Semua Training"

}) => {

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="AboutUs">
        About
      </NavLink>
      <NavLink href="Blogs">
        Blogs
      </NavLink>
      <NavLink href="Training">
        Training
      </NavLink>
      {/* <NavLink href="icekape">
        IceKape
      </NavLink> */}
      {/* <NavLink href="Consulting">
        Retail Consulting
      </NavLink> */}
      <NavLink href="OdooConsulting">
        Odoo Consulting
      </NavLink>
      <NavLink href="Cloud">
        Odoo Cloud Solutions
      </NavLink>
      {/* <NavLink href="Distribution">
        Distribution
      </NavLink> */}
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="#contactus">
        Hubungi Kami
      </PrimaryLink>
    </NavLinks>
  ];

  return(
  <AnimationRevealPage>
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/* <Notification>We have now launched operations in Europe.</Notification> */}
            <HeadingHero>
              <span>Beyond Solution</span>
              <br />
              {/* <SlantedBackground>With Us</SlantedBackground> */}
            </HeadingHero>
            <Actions>
              <PrimaryAction href={portfoliourl}>Testimoni Pelanggan</PrimaryAction>
            </Actions>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
    <MainFeature />
    <Features /> 
    <MainFeature2 />
    <SectionContainer id="portfolio">
    <Portfolio />
    </SectionContainer>
    <Blog />
    <SectionContainer id="contactus">
    <ContactUsForm />
    {/* <ContainerContactUs>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <SubheadingContactUs>{subheading}</SubheadingContactUs>}
            <HeadingContactUs>{heading}</HeadingContactUs>
            <Description>{description}</Description>
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </ContainerContactUs> */}
    </SectionContainer>
    <Footer />

  </AnimationRevealPage>
  );
};
