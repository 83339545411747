import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { SectionHeading2 } from "components/misc/Headings2";
import { PrimaryButton } from "components/misc/Buttons";
import bm31 from "../images/bm3-1.jpg";
import Features from "components/features/ThreeColCenteredStatsPrimaryBackgroundForTraining";

// const Container = tw.div`relative mb-8`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const HeadingRow2 = tw.div`flex`;
const Heading2 = tw(SectionHeading2)`text-gray-900`;
const Posts2 = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;

const PostContainer2 = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;


const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;

const Post2 = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image2 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;



const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const Info2 = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category2 = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate2 = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title2 = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description2 = tw.div``;



const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const ButtonContainer2 = tw.div`flex justify-center`;
const LoadMoreButton2 = tw(PrimaryButton)`mt-16 mx-auto`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;


export default ({
  headingText = "Training Bisnis Retail",
  headingText2 = "Training Odoo",
  posts = [
    // {
    //   // imageSrc:
    //   //   "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
    //   // category: "Travel Tips",
    //   // date: "April 21, 2020",
    //   // title: "Safely Travel in Foreign Countries",
    //   // description:
    //   //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   // url: "https://timerse.com",
    //   // featured: true
    // },
    getPlaceholderPost(),
    getPlaceholderPost2(),
    getPlaceholderPost3(),
    getPlaceholderPost4(),
    getPlaceholderPost5(),
    getPlaceholderPost6(),
  ],
  posts2 = [
    // {
    //   // imageSrc:
    //   //   "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
    //   // category: "Travel Tips",
    //   // date: "April 21, 2020",
    //   // title: "Safely Travel in Foreign Countries",
    //   // description:
    //   //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   // url: "https://timerse.com",
    //   // featured: true
    // },
    getPlaceholderPost7(),
    getPlaceholderPost8(),
    getPlaceholderPost9(),
    // getPlaceholderPost10(),
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
        {/* <PrimaryLink css={false && tw`rounded-full`}href="/Schedule">Jadwal Pelatihan</PrimaryLink> */}
          <Features />
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText2}</Heading>
          </HeadingRow>
          <Posts>
            {posts2.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>

);
};


const getPlaceholderPost = () => ({
  imageSrc:
    "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Basic Retail",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB001"
});

const getPlaceholderPost2 = () => ({
  imageSrc:
  "https://source.unsplash.com/t5YUoHW6zRo/600x300",  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Product Management",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB002"
});

const getPlaceholderPost3 = () => ({
  imageSrc:
  "https://source.unsplash.com/2Q8bo_6lu1Y/600x300",
  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Pelayanan Prima",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB003"
});

const getPlaceholderPost4 = () => ({
  imageSrc:
    "https://source.unsplash.com/kTd2PvtqE_o/600x300",

  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Pricing and Competition In Retail",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB004"
});

const getPlaceholderPost5 = () => ({
  imageSrc:
      "https://source.unsplash.com/BTKF6G-O8fU/600x300",
      category: "Bisnis Retail",
      date: "April 19, 2022",
      title: "Promotion In Retail",
      description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB005"
});

const getPlaceholderPost6 = () => ({
  imageSrc:
    "https://source.unsplash.com/hpjSkU2UYSU/600x300",
    // "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Management Retail dan Laporan Keuangan",
  description:
    "Membahas tentang apa itu manajemen retail hingga laporan keuangan",
  url: "/RB006"
});

const getPlaceholderPost7 = () => ({
  imageSrc: bm31,
    // "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Functional Mini Market Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB007"
});

const getPlaceholderPost8 = () => ({
  imageSrc:
    "https://source.unsplash.com/5fNmWej4tAA/600x300",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Odoo Functional Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB008"
});

const getPlaceholderPost9 = () => ({
  imageSrc:
    "https://source.unsplash.com/vpOeXr5wmR4/600x300",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Odoo Developer Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB009"
});

// const getPlaceholderPost10 = () => ({
//   imageSrc:
//     "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
//   category: "Travel Guide",
//   date: "April 19, 2020",
//   title: "Visit the beautiful Alps in Switzerland",
//   description:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//   url: "https://reddit.com"
// });
