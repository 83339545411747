import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import Consulting from "pages/Consulting";
import MainLandingPage from "MainLandingPage.js";
import Event from "demos/EventLandingPage.js";
import Hotel from "demos/HotelTravelLandingPage.js";
import Restaurant from "demos/RestaurantLandingPage.js";
import ThankYouPage from "ThankYouPage.js";
import Cloud from "pages/Cloud.js";
import Saas from "demos/SaaSProductLandingPage.js";
import Services from "demos/ServiceLandingPage.js";
import Pricing from "pages/Pricing.js";
import ContactUs from "pages/ContactUs";
import Blogs from "pages/BlogIndexForKape.js";
import IceKape from "pages/IceKape";
import AboutUs from "pages/AboutUs";
import Training from "pages/Training";
import BL001 from "blogs/bl001.js";
import BL002 from "blogs/bl002.js";
import BL003 from "blogs/bl003.js";
import BL004 from "blogs/bl004.js";
import BL005 from "blogs/bl005.js";
import BL006 from "blogs/bl006.js";

import RB001 from "training/RB001.js";
import RB002 from "training/RB002.js";
import RB003 from "training/RB003.js";
import RB004 from "training/RB004.js";
import RB005 from "training/RB005.js";
import RB006 from "training/RB006.js";
import RB007 from "training/RB007.js";
import RB008 from "training/RB008.js";
import RB009 from "training/RB009.js";
import Schedule from "training/Schedule";
import OdooConsulting from "pages/OdooConsulting";
import ProjectArfa from "projects/01-arfa.js";
import ProjectBm3 from "projects/02-bm3.js";
import ProjectList from "projects/Projectlist";
import Distribution from "pages/Distribution";
import Ufia from "distributions/Ufia";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GridWithFeaturedPost from "components/blogs/GridWithFeaturedPost";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Router>
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/thank-you">
          <ThankYouPage />
        </Route>
        <Route path="/Cloud">
          <Cloud/>
        </Route>
        <Route path="/Event">
          <Event />
        </Route>
        <Route path="/Hotel">
          <Hotel />
        </Route>
        <Route path="/Restaurant">
          <Restaurant />
        </Route>
        <Route path="/Training">
          <Training />
        </Route>
        <Route path="/Saas">
          <Saas />
        </Route>
        <Route path="/Services">
          <Services />
        </Route>
        <Route path="/Blogs">
          <Blogs />
        </Route>
        <Route path="/AboutUs">
          <AboutUs />
        </Route>
        <Route path="/icekape">
          <IceKape />
        </Route>
        <Route path="/Pricing">
          <Pricing />
        </Route>
        <Route path="/ContactUs">
          <ContactUs />
        </Route>
        <Route path="/Distribution">
          <Distribution />
        </Route>
        <Route path="/bl001">
          <BL001 />
        </Route>
        <Route path="/bl002">
          <BL002 />
        </Route>
        <Route path="/bl003">
          <BL003 />
        </Route>
        <Route path="/bl004">
          <BL004 />
        </Route>
        <Route path="/bl005">
          <BL005 />
        </Route>
        <Route path="/bl006">
          <BL006 />
        </Route>
        <Route path="/RB001">
          <RB001 />
        </Route>
        <Route path="/RB002">
          <RB002 />
        </Route>
        <Route path="/RB003">
          <RB003 />
        </Route>
        <Route path="/RB004">
          <RB004 />
        </Route>
        <Route path="/RB005">
          <RB005 />
        </Route>
        <Route path="/RB006">
          <RB006 />
        </Route>
        <Route path="/RB007">
          <RB007 />
        </Route>
        <Route path="/RB008">
          <RB008 />
        </Route>
        <Route path="/RB009">
          <RB009 />
        </Route>
        <Route path="/Schedule">
          <Schedule />
        </Route>
        <Route path="/Consulting">
          <Consulting />
        </Route>
        <Route path="/OdooConsulting">
          <OdooConsulting />
        </Route>
        <Route path="/projects/01-arfa">
          <ProjectArfa />
        </Route>
        <Route path="/projects/02-bm3">
          <ProjectBm3 />
        </Route>
        <Route path="/projects/Projectlist">
          <ProjectList />
        </Route>
        <Route path="/distributions/Ufia">
          <Ufia />
        </Route>
        <Route path="/">
          <MainLandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
