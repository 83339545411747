import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "RB-006 Management Retail dan Laporan Keuangan" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2022</p>
            <h2>Tujuan :</h2>
            <p>
            <ol>
              <li>Apa itu Retail Management</li>
              <li>Empat fungsi utama management retail</li>
              <li>Memahami Retail Management Proses</li>
              <li>Laporan Keuangan Retail</li>
            </ol>
            </p>
            <h2>Durasi : </h2>2 hari
            <h2>Requirement : </h2><p>Paham atau Pernah terjun ke Bisnis Retail min 6 Bulan .</p>
            <h2>Investasi :</h2><p>Rp 1.500.000</p>

            <h3>Sesi  1 : Memahami pengertian Retail Management</h3>
            {/* <p>
            Sesi pertama akan memberikan pemahaman ke peserta Training mengenai status Promosi sebagai salah satu strategi dalam bisnis retail yang tidak bisa dipisahkan dengan strategi lainnya yang harus bisa bersinergi untuk mendapatkan tujuan atau goal yang ditetapkan
            </p> */}
            <h3>Sesi  2 : Memahami Keterkaitan 4 fungsi utama retail</h3>
            <p>
            Akan menjelaskan 4 fungsi utama management retail : 
            <ol>
              <li>Management Inventory</li>
              <li>Employee Management</li>
              <li>Store Layout Design Planning</li>
              <li>Setting Sales Goal</li>
            </ol>
            </p>
            <h3>Sesi  3 :Memahami Retail Management Process:</h3>
            <ol>
              <li>Identify the problem you want to solve</li>
              <li>Understand the people you want to help (customer)</li>
              <li>Develop and test your product or services</li>
              <li>Address all legal and financial matters</li>
              <li>Specify and prepare administrative needs</li>
            </ol>
            <h3>Sesi  4 : Laporan Keuangan Retail</h3>
            <p>Beberapa perbedaan akuntansi perusahaan retail dengan perusahaan jasa, Akun-akun perusahaan dagang yang tidak ada pada perusahaan jasa</p>
            <ol>
              <li>Akun Pembelian</li>
              <li>Akun Penjualan</li>
              <li>Akun Persediaan</li>
              <li>Harga Pokok Penjualan atau HPP</li>
              <li>Akun Potongan Tunai</li>
              <li>Akun Potongan Pembelian</li>
              <li>Akun Retur Penjualan</li>
              <li>Beban Pemasaran</li>
            </ol>
            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
