import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import Sony from "images/sony.JPG";
import Yomi from "images/yomi.JPG";
import Suryadi from "images/suryadi.JPG";
import Randy from "images/randy.png";
import Silvi from "images/silvi.png";
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";



const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const Cards2 = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card2 = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`

const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-48 h-72 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "Para Founder dan Co Founder",
  subheading = "Team Kami",
  description = "Pengalaman lebih dari 20 tahun di Industri Retail dan Teknologi Informasi, pernah menjabat manajemen di beberapa perusahaan PMDN dan PMA",
  cards = [
    {
      imageSrc: Yomi,
      position: "Founder",
      name: "Yomi Yunus",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        // {
        //   url: "https://linkedin.com",
        //   icon: LinkedinIcon,
        // },
        {
          url: "https://instagram.com/yomiyunus",
          icon: InstagramIcon,
        },
      ],
    },    
    {
      imageSrc: Sony,
      position: "Founder",
      name: "Sony Setiawan",
      links: [
        {
          url: "https://twitter.com/sonyse",
          icon: TwitterIcon,
        },
        {
          url: "https://www.linkedin.com/in/sonyse/",
          icon: LinkedinIcon,
        },
        {
          url: "https://instagram.com/sonyse",
          icon: InstagramIcon,
        },
      ],
    }
  ],
  cards2 = [
    {
      imageSrc: Silvi,
      position: "Co Founder",
      name: "Silvi Peli Joedha",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        // {
        //   url: "https://linkedin.com",
        //   icon: LinkedinIcon,
        // },
        {
          url: "https://instagram.com",
          icon: InstagramIcon,
        },
      ],
    },
    {
      imageSrc: Randy,
      position: "Co Founder",
      name: "Randy Anwar",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        // {
        //   url: "https://linkedin.com",
        //   icon: LinkedinIcon,
        // },
        {
          url: "https://instagram.com/randwar",
          icon: InstagramIcon,
        },
      ],
    },
    {
      imageSrc: Suryadi,
      position: "Co Founder",
      name: "Suryadi Mawansyah",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        {
          url: "https://www.linkedin.com/in/suryadi-mawansyah-089776ba",
          icon: LinkedinIcon,
        },
        {
          url: "https://instagram.com/suryadimawan",
          icon: InstagramIcon,
        },
      ],
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
        <Cards2>
          {cards2.map((card2, index) => (
            <Card2 key={index}>
              <CardImage imageSrc={card2.imageSrc} />
              <CardContent>
                <span className="position">{card2.position}</span>
                <span className="name">{card2.name}</span>
                <CardLinks>
                  {card2.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card2>
          ))}
        </Cards2>
      </ContentWithPaddingXl>
    </Container>
  );
};
