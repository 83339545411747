import React from "react";
import tw from "twin.macro";
// import { HashRouter as Router, Route, Link } from 'react-router-dom';
import MainLandingPage from "MainLandingPage.js";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Link} from "react-scroll";
import Event from "demos/EventLandingPage.js";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";



const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-100 opacity-25`;
// const SectionContainer = tw(ContentWithVerticalPadding)``;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-2 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-2xl lg:text-3xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-red-600 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-red-500 font-bold rounded shadow transition duration-300 hocus:bg-red-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;
const portfoliourl = "#portfolio"



export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="AboutUs">
        About
      </NavLink>
      <NavLink href="Training">
        Training
      </NavLink>
      <NavLink href="Consulting">
        Retail Consulting
      </NavLink>
      <NavLink href="OdooConsulting">
        Odoo Consulting
      </NavLink>
      <NavLink href="Distribution">
        Distribution
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#">
        Hubungi Kami
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/* <Notification>We have now launched operations in Europe.</Notification> */}
            <Heading>
              <span>Menjadi Lebih Baik</span>
              <br />
              <SlantedBackground>Bersama Kami</SlantedBackground>
            </Heading>
            <Actions>
              <PrimaryAction href={portfoliourl}>Testimoni Pelanggan</PrimaryAction>
            </Actions>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
