import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/kcs_logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";


const Container = tw(ContainerBase)`bg-gray-400 text-purple-600 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} alt="Kape Solusi" />
            {/* <LogoText>DRISS</LogoText> */}
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/AboutUs">About</Link>
            <Link href="/Blogs">Blogs</Link>
            {/* <Link href="/Training">Training</Link>
            <Link href="/Consulting">Retail Consulting</Link> */}
            {/* <Link href="/IceKape">IceKape</Link> */}
            <Link href="/OdooConsulting">Odoo Consulting</Link>
            <Link href="/Cloud">Odoo Cloud Solutions</Link>
            {/* <Link href="/Distribusi">Distribusi</Link> */}
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@kapesolusi9902" target="_blank">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/kape.solusi" target="_blank">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            {/* &copy; Copyright 2022, PT Solusi Retail Teknologi. All Rights Reserved. */}
            &copy; Copyright 2023, PT. Kape Cipta Solusi. All Rights Reserved.
            </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
