import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { SectionHeading2 } from "components/misc/Headings2";
import { PrimaryButton } from "components/misc/Buttons";
import icekapecontact from "components/forms/SimpleSubscribeNewsletterKape";
import bm31 from "../images/bm3-1.jpg";
import cupconeimg from "../images/cup-cone.png";
import sharpconeimg from "../images/sharp-cone.png";
import flattopimg from "../images/flat-top-02.png";
import classicimg from "../images/classic-02.png";
import vanillaimg from "../images/vanila-02.png";
import chocolateimg from "../images/chocolate-02.png"; 
import strawberryimg from "../images/strawberry-02.png";
import matchaimg from "../images/matcha-02.png";
import blackcharcoalimg from "../images/black-charcoal-02.png";
import mangoimg from "../images/mango-02.png";
import bananaimg from "../images/banana-02.png";
import lemonimg from "../images/lemon-charcoal-02.png";
import taroimg from "../images/taro-02.png";
import s191img from "../images/sb191-02.png";
import s636img from "../images/s636-02.png";
import b819img from "../images/b819-02.png";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustratioForKape.js";
import banner_web from "images/banner_web.png";

import Features from "components/features/ThreeColCenteredStatsPrimaryBackgroundForTraining";

// const Container = tw.div`relative mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const HeadingRow2 = tw.div`flex`;
const Heading2 = tw(SectionHeading2)`text-gray-900`;
const Posts2 = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;

const PostContainer2 = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;


const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`flex justify-center mx-auto h-64 w-64 items-center bg-cover bg-center rounded-t-lg`}
`;

const Post2 = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image2 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`flex justify-center h-96 w-full items-end bg-cover bg-top`}
  `;



const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 font-bold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div`mt-4 text-gray-600 italic font-semibold text-xs`;
const Description2 = tw.div`mt-4 text-gray-600 italic font-semibold text-xs`;
const Description3 = tw.div`mt-4 text-gray-600 italic font-semibold text-xs`;
const Description4 = tw.div`mt-4 text-gray-600 italic font-semibold text-xs`;

const Info2 = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category2 = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate2 = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title2 = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
// const Description2 = tw.div``;



const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const ButtonContainer2 = tw.div`flex justify-center`;
const LoadMoreButton2 = tw(PrimaryButton)`mt-16 mx-auto`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;


export default ({
  headingText = "Mesin Soft Ice Cream",
  headingText2 = "Bahan Baku Ice Cream",
  posts = [
    // {
    //   // imageSrc:
    //   //   "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
    //   // category: "Travel Tips",
    //   // date: "April 21, 2020",
    //   // title: "Safely Travel in Foreign Countries",
    //   // description:
    //   //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   // url: "https://timerse.com",
    //   // featured: true
    // },
    s191(),
    s636(),
    b819(),
  ],
  posts2 = [
    // {
    //   // imageSrc:
    //   //   "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
    //   // category: "Travel Tips",
    //   // date: "April 21, 2020",
    //   // title: "Safely Travel in Foreign Countries",
    //   // description:
    //   //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   // url: "https://timerse.com",
    //   // featured: true
    // },
    cupcone(),
    sharpcone(),
    flattop(),
    classic(),
    vanillapowder(),
    chocolatepowder(),
    strawberrypowder(),
    matchapowder(),
    blackcharcoalpowder(),
    mangopowder(),
    bananapowder(),
    lemonpowder(),
    taropowder(), 
    vanilasp(),
    strawberrysp(),
    chocolatesp(),

    // getPlaceholderPost7(),
    // getPlaceholderPost8(),
    // getPlaceholderPost9(),
    // getPlaceholderPost10(),
  ]
}) => {
  const [visible, setVisible] = useState(20);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      {/* <Container>
        <ContentWithPaddingXl>
        <PrimaryLink css={false && tw`rounded-full`}href="/Schedule">Jadwal Pelatihan</PrimaryLink>
          <Features />
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container> */}
      {/* <ContactUsForm />       */}
      <Container>
        <Content>
          <HeadingRow>
            <Heading>Simulasi Perhitungan Bisnis</Heading>
          </HeadingRow>
          <Image2 imageSrc={banner_web} />
        </Content>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText2}</Heading>
          </HeadingRow>
          <Posts>
            {posts2.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <Title>{post.title}</Title>
                    <CreationDate>{post.price}</CreationDate>
                    <Description>{post.description}</Description>
                    <Description2>{post.Description2}</Description2>
                    <Description3>{post.Description3}</Description3>
                    <Description4>{post.Description4}</Description4>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <Title>{post.title}</Title>
                    <CreationDate>{post.price}</CreationDate>
                    <Description>{post.description}</Description>
                    <Description2>{post.Description2}</Description2>
                    <Description3>{post.Description3}</Description3>
                    <Description4>{post.Description4}</Description4>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>

);
};


const cupcone = () => ({
  imageSrc:cupconeimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Wafer Cake Cone",
  price: "Rp 450",
  title: "Cup Cone",
  description:
    "External Diameter : 58 - 60 mm",
  Description2:"Height : 71 - 73 mm",
  Description3:"Weight : 4 - 6 gram",
  url: "/RB001"
});

const sharpcone = () => ({
  imageSrc:sharpconeimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Wafer Cake Cone",
  price: "Rp 600",
  title: "Sharp Cone",
  description:"External Diameter : 53 - 57 mm",
  Description2 : "Height : 123 - 127 mm",
  Description3 : "Weight : 4 - 6 gram",
});

const flattop = () => ({
  imageSrc:flattopimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Sugar Cone",
  price: "Rp 600",
  title: "Flat Top",
  description:"External Diameter : 44 - 48 mm",
  Description2 : "Height : 108 - 114 mm",
  Description3 : "Weight : 12,8 - 15 gram",
});

const classic = () => ({
  imageSrc:classicimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Sugar Cone",
  price: "Rp 900",
  title: "Classic",
  description:"External Diameter : 64 - 68 mm",
  Description2 : "Height : 142 - 146 mm",
  Description3 : "Weight : 15,5 - 17,5 gram",
});

const vanillapowder = () => ({
  imageSrc:vanillaimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 142.700",
  title: "Vanilla Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const chocolatepowder = () => ({
  imageSrc:chocolateimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 143.100",
  title: "Chocolate Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});


const strawberrypowder = () => ({
  imageSrc:strawberryimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 140.900",
  title: "Strawberry Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const matchapowder = () => ({
  imageSrc:matchaimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 160.600",
  title: "Matcha Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const blackcharcoalpowder = () => ({
  imageSrc:blackcharcoalimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 173.400",
  title: "Black Charcoal Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const mangopowder = () => ({
  imageSrc:mangoimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 148.600",
  title: "Mango Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const bananapowder = () => ({
  imageSrc:bananaimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 146.400",
  title: "Banana Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const lemonpowder = () => ({
  imageSrc:lemonimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 185.200",
  title: "Lemon Charcoal Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const taropowder = () => ({
  imageSrc:taroimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 184.800",
  title: "Taro Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const vanilasp = () => ({
  imageSrc:vanillaimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 91.600",
  title: "Vanila SP Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});


const strawberrysp = () => ({
  imageSrc:strawberryimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 97.500",
  title: "Strawberry SP Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});


const chocolatesp = () => ({
  imageSrc:chocolateimg,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Soft Ice Mix Powder",
  price: "Rp 99.900",
  title: "Chocolate SP Powder",
  description:"Weight : 1,5 kg",
  Description2 : "Mix Air : 3,5 liter",
});

const s191 = () => ({
  imageSrc:s191img,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Countertop Machine",
  price: "Rp 64.380.000",
  title: "S191",
  description:"Flavor : Single",
  Description2 : "Output Capacity : >270 serving/hr",
  Description3 : "Size (L x W x H) : 52cm x 85cm x 85cm"
});

const s636 = () => ({
  imageSrc:s636img,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Floor Standing Machine",
  price: "Rp 67.710.000",
  title: "S636",
  description: "2 Flavor 1 Mix",
  Description2 : "Output Capacity : >460 serving/hr",
  Description3 : "Size (L x W x H) : 54cm x 77cm x 144cm"
});

const b819 = () => ({
  imageSrc:b819img,
    // "https://source.unsplash.com/tWXH_zGJrPo/600x300",
  category: "Table Standing Machine",
  price: "Rp 45.399.000",
  title: "B819",
  description: "2 Flavor 1 Mix",
  Description2 : "Output Capacity : >460 serving/hr",
  Description3 : "Size (L x W x H) : 52cm x 85cm x 85cm"
});



const getPlaceholderPost2 = () => ({
  imageSrc:
  "https://source.unsplash.com/t5YUoHW6zRo/600x300",  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Product Management",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB002"
});

const getPlaceholderPost3 = () => ({
  imageSrc:
  "https://source.unsplash.com/2Q8bo_6lu1Y/600x300",
  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Pelayanan Prima",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB003"
});

const getPlaceholderPost4 = () => ({
  imageSrc:
    "https://source.unsplash.com/kTd2PvtqE_o/600x300",

  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Pricing and Competition In Retail",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB004"
});

const getPlaceholderPost5 = () => ({
  imageSrc:
      "https://source.unsplash.com/BTKF6G-O8fU/600x300",
      category: "Bisnis Retail",
      date: "April 19, 2022",
      title: "Promotion In Retail",
      description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB005"
});

const getPlaceholderPost6 = () => ({
  imageSrc:
    "https://source.unsplash.com/hpjSkU2UYSU/600x300",
    // "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Bisnis Retail",
  date: "April 19, 2022",
  title: "Management Retail dan Laporan Keuangan",
  description:
    "Membahas tentang apa itu manajemen retail hingga laporan keuangan",
  url: "/RB006"
});

const getPlaceholderPost7 = () => ({
  imageSrc: bm31,
    // "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Functional Mini Market Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB007"
});

const getPlaceholderPost8 = () => ({
  imageSrc:
    "https://source.unsplash.com/5fNmWej4tAA/600x300",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Odoo Functional Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB008"
});

const getPlaceholderPost9 = () => ({
  imageSrc:
    "https://source.unsplash.com/vpOeXr5wmR4/600x300",
  category: "Odoo",
  date: "April 19, 2022",
  title: "Basic Odoo Developer Training",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/RB009"
});

// const getPlaceholderPost10 = () => ({
//   imageSrc:
//     "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
//   category: "Travel Guide",
//   date: "April 19, 2020",
//   title: "Visit the beautiful Alps in Switzerland",
//   description:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//   url: "https://reddit.com"
// });
