import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import ArfaPics from "images/arfa.JPG"
import Bm3 from "images/bm3display.jpg"
import Sb from "images/swissbakery-1.png"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold bg-red-500 text-red-600
  hocus:bg-red-600 text-gray-200`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Proyek",
  heading = <>Beberapa<span tw="text-red-500"> Proyek Kami</span></>,
  description = "",

}) => {
  const blogPosts = [
    {
      imageSrc:Bm3,
      author: "Dr. Maya Yunus",
      category: "Retail/Konveksi/Catering ",
      title: "BMBC",
      description: "BMBC sudah sejak 2021 menggunakan Odoo untuk membantu proses bisnis dari yayasan baitul Maal untuk yang bergerak untuk dibidang niaga, diantaranya adalah mini market , konveksi dan catering. Seiring dengan perkembangan bisnis setelah masuk ke tahun ke 2 BMBC merasa perlu ditingkatkan peran odoo-nya untuk membantu pembuatan laporan keuangan dan merapihkan beberapa proses yang tentunda selama 2 tahun pasca pandemi",
      url: "/RB001"
    },
    {
      imageSrc: Sb,
      author: "Hariyanto",
      category: "Food and Beverage",
      title: "Swiss Bakery",
      description: "Swiss bakery merupakan salah satu toko roti terlama di kota semarang, keputusan menggunakan odoo dikarenakan sudah mempunyai 22 cabang yang tersebar di beberapa kota besar di jawa tengah, seperti tegal, semarang, solo. Dengan semakin banyak cabang tersebar diharapkan odoo mampu untuk mengontrol inventory dan penjualan dimasing-masing cabang.",
      url: "/RB005"
    },
    {
      imageSrc:ArfaPics,
      author: "Gilang Embang",
      category: "Supplier Kebutuhan Kedai",
      title: "ARFA Suppliers",
      description: "Industri kuliner dan kopi di Tangerang semakin menjamur, Arfa Suppliers sebagai salah satu pelopor di bidang supply alat dan bahan-bahan kedai berusaha untuk mengontrol ketersediaan bahan baku dan penjualan sehari-hari menjadikan odoo sebagai sistem untuk mengontrol segala keperluan penjualan dan pembelian barang yang akan dijual.",
      url: "/RB007"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Lihat Sylabus</Link> */}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
