import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { SectionHeading2 } from "components/misc/Headings2";
import { PrimaryButton } from "components/misc/Buttons";
import CssBaseline from '@material-ui/core/CssBaseline'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { useTable } from 'react-table'

import makeData from './makeData'

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Jadwal Kelas</Heading>
          </HeadingRow>
          <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </MaUTable>
          </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
    }



function App() {
  const data = React.useMemo(
    () => [
      {
        classname: 'Basic Retail (1 hari)',
        mei: '17,30',
        jun: '8,20,30',
        jul: '11,20',
      },
      {
        classname: 'Product Management (1 hari)',
        mei: '18,31',
        jun: '9,21',
        jul: '1,12,21',
      },
      {
        classname: 'Pricing and Competition in Retail (1 hari)',
        mei: '19',
        jun: '1,10,22',
        jul: '4,13,22',
      },
      {
        classname: 'Pelayanan Prima (1 hari)',
        mei: '20',
        jun: '13,23',
        jul: '5,14',
      },
      {
        classname: 'Promotion in Retail (1 hari)',
        mei: '23',
        jun: '3,14,24',
        jul: '6,15',
      },
      {
        classname: 'Management Retail & Financial Reporting (2 hari)',
        mei: '24-25',
        jun: '15-16,27-28',
        jul: '7-8,18-19,27-28',
      },
      {
        classname: 'Odoo Functional Mini Market (2 hari)',
        mei: '17-18,30-31',
        jun: '9-10,20-21',
        jul: '4-5,14-15,25-26',
      },
      {
        classname: 'Odoo Functional Non Retail (2 hari)',
        mei: '19-20`',
        jun: '1-2,13-14,22-23',
        jul: '6-7,18-19,27-28',
      },
      {
        classname: 'Odoo Development (3 hari)',
        mei: '23-25',
        jun: '6-8,15-17,27-29',
        jul: '11-13,20-22',
      },
    ],
    []
  )
  const columns = React.useMemo(
    () => [

      {
        Header: 'Mei - Juli 2022',
        columns: [
          {
            Header: 'Nama Kelas',
            accessor: 'classname',
          },
          {
            Header: 'Mei',
            accessor: 'mei',
          },
          {
            Header: 'Jun',
            accessor: 'jun',
          },
          {
            Header: 'Jul',
            accessor: 'jul',
          },
        ],
      },
    ],
    []
  )


  return (
    <div>
      <CssBaseline />
      <Table columns={columns} data={data} />
    </div>
  )
}

export default App