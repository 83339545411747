import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import odoo17 from "images/odoo_apps17.png";
import sale1 from "images/sale2.png";
import so1 from "images/so-1.png";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;


    // class="mb-4 mt-0 text-base font-light leading-relaxed"
    // ${tw`mt-0 leading-loose`}

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mb-4 mt-0 text-base font-light leading-relaxed`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`ml-3 list-disc list-outside`}
    li {
      ${tw`ml-3 mb-0 list-decimal`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "Pengenalan modul Sales" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p >Last updated: April 29, 2020</p>
            <h2>Sekilas modul penjualan</h2>
            <br />
            <p>
            Kelola sale order, customer service, dan toko eCommerce pada satu platform. Itu salah satu tagline dari module odoo sales berdasarkan website resmi di odoo.com. Penjualan yang dilakukan bisa langsung datang ke toko atau penjualan tempo dengan cara pesanan yang tidak harus dikirim saat itu juga. Bisa juga untuk penjualan jasa atau pun penjualan lewat e-commerce. Fitur Fitur lainnya adalah discount, promo gift card, e-wallet. 
            <img size="75%" width="800" height="328" src={sale1}></img>
            </p>
            <p>
            Apa yang dimaksud dengan application framework, gambarannya adalah odoo sudah mempunyai beberapa standard module seperti sales, purchasing, inventory, accounting akan tetapi dari sisi kemudahan customization dan integrasinya itu kami menilai odoo akan menjadi sebuah platform masa depan yang bisa dikembangkan menyesuaikan kebutuhan operasional. Dalam implementasi-nya kami seperti biasa harus terlebih dahulu membuat assessment terlebih dahulu untuk bisnis proses yang customer inginkan.            
            </p>
            <p>Module sales dalam odoo ada banyak ragam dan kegunaannya yang disesuaikan dengan kebutuhan, mari kita bahas satu per satu.</p>
            {/* <h2>Beberapa modul standard odoo</h2> */}
            <br />
            <ol>
              <li>Sales Order <br />
<p>Digunakan untuk keperluan penjualan yang berdasarkan pesanan, tidak harus langsung dikeluarkan barang atau jasa-nya. Proses pencatatannya akan melalu beberapa tahap, mulai dari penawaran, so confirm, delivery order dan invoicing. Jika penjualannya adalah jasa bisa menyesuaikan dengan progres pekerjaan.
<br />
<img src={so1} width="400" height="409"></img>
</p>
</li>
              <li>Point of Sales<br />
              <p>Digunakan untuk mencatat penjualan langsung, datang ke tempat pilih barang bawa ke kasir dan melakukan transaksi di kasir. Outputnya berupa struk / nota penjualan yang dijadikan sebagai bukti pembayaran, akan terjadi juga pencatatan transaksi jurnal penjualan dan juga transaksi pengeluaran barang untuk memotong stok dari persediaan barang.</p>
              </li>
              <li>Sale Subscription (enterprise version)<br />
              <p>Digunakan untuk jenis transaksi yang sifatnya perulangan bisa sebulan sekali per 3 bulan atau setahun sekali dan masih bisa dikonfigurasi menyesuaikan dengan kebutuhan. Disamping itu ada proses generate invoice recurring yang bisa digenerate otomatis ataupun manual.</p>
              </li>
              <li>Rental Order (enterprise version)<br />
              <p>
              Digunakan untuk jenis penjualan rental barang atau peralatan yang bisa diketahui kapan dikembalikan dan barang tersebut ada di customer yang mana, dan tentunya bisa mengelola penagihan dan pencatatan penjualan tipe rental ini. 
              </p>
              </li>
              <li>E-commerce<br />
              <p>
              Digunakan untuk mencatat penjualan langsung menggunakan web marketplace seperti layaknya marketplace publik yang ada saat ini, ketika ada transaksi yang terjadi akan masuk ke dalam sale order dan melakukan proses packing dan pengiriman barang yang dikeluarkan dari modul inventory.  
              </p>
              </li>
            </ol>
            <p>
            </p>
            <p>Jika ingin lebih mengenal odoo silakan hubungi kami by whatsapp</p>

            {/* <h3>Sesi  2 : Memahami Pengertian retail </h3>
            <p>
            Memahami Fungsi dan jenis jenis perusahaan Retail            </p>
            <h3>Sesi  3 : Memahami matematika retail  </h3>
            <p>
            Akan menjabarkan konsep Matematika retail yang umum digunakan dalam bisnis retail             </p>


            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p> */}
            <br />
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6285781574709&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
