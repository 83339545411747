import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "RB-003 Pelayanan Prima" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2022</p>
            <h2>Tujuan :</h2>
            <p>
            Memahami Pentingnya sikap, perhatian, tindakan, kemampuan, penampilan dan tanggung jawab terhadap customer dalam mengelola Bisnis retail
            </p>
            <h2>Durasi : </h2>1 hari
            <h2>Requirement : </h2><p>Paham atau Pernah terjun ke Bisnis Retail min 6 Bulan .</p>
            <h2>Investasi :</h2><p>Rp 750.000</p>

            <h3>Sesi  1 : Memahami pengertian pelayanan Prima di Bisnis retail</h3>
            <p>
            Akan mendetailkan tip tips yang bisa dilakukan hasil pengalaman di bisnis retail yang dapat mensupport pencapaian keuntungan yang diharapkan</p>
            <h3>Sesi  2 : Memahami dampak pelaksanaan Pelayanan Prima secara Simultan</h3>
            <p>
            Sesi kedua akan memberikan pemahaman tentang pentingnya penerapan pelayanan prima secara simultan di setiap usaha retail : 
            <ol>
              <li>Sikap melayani Customer</li>
              <li>Tindakan pada saat melayani customer</li>
              <li>Kemampuan dalam melayani customer</li>
              <li>Penampilan pada saat melayani customer</li>
              <li>Tanggungjawab pada saat melayani customer</li>
            </ol>
            </p>
            <h3>Sesi  3 : Memahami Penerapan Pelayanan prima secara parsial</h3>
            <p>Akan menjabarkan secara detail variable mana dari 5 point dari pelayanan prima yang paling berpengaruh terhadap kepuasan customer</p>

            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
