import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "RB-004 Pricing and Competition In Retail" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2022</p>
            <h2>Tujuan :</h2>
            <p>
            <ol>
              <li>Memahami Strategi Harga dalam bisnis retail </li>
              <li>Memahami Pendekatan dalam penetapan Harga</li>
              <li>Memahami Komponen dalam membangun reputasi harga</li>
              <li>Memahami Penyesuaian Harga dalam retail</li>
            </ol>
            </p>
            <h2>Durasi : </h2>1 hari
            <h2>Requirement : </h2><p>Paham atau Pernah terjun ke Bisnis Retail min 6 Bulan .</p>
            <h2>Investasi :</h2><p>Rp 750.000</p>

            <h3>Sesi  1 : Memahami Strategi Harga dalam bisnis retail dan pendekatan dalam penetapannya</h3>
            <p>
            Sesi pertama akan memberikan pemahaman ke peserta Training mengenai 2 strategi harga yang umum dilakukan oleh peritel Besar pada produk dagangannya, yaitu EDLP dan HLPD Disini akan diberikan secara detail manfaat terhadap diperlakukan strategi EDLP dan HLP , dan faktor faktor apa saja yang membuat para peritel memilih salah satu dari strategi di atas
            </p>
            <p>
            Di sesi ini juga akan disampaikan pendekatan penetapan harga, yaitu :
            <ol>
              <li>Metode penetapan harga jual Impas </li>
              <li>Metode penetapan harga yang berorientasi terhadap permintaan</li>
              <li>Metode penetapan harga yang berorientasi terhadap persaingan</li>
            </ol>

             
            </p>
            <h3>Sesi  2 : Memahami Komponen dalam membangun reputasi Retail</h3>
            <p>
            Akan menjelaskan 5 komponen yang dapat mendukung reputasi harga di toko toko retail : 
            <ol>
              <li>Everyday Shelf Prices</li>
              <li>Price Communication</li>
              <li>Promotion Price</li>
              <li>Per Unit prices</li>
              <li>Know- Value item Price</li>
            </ol>
            </p>
            <h3>Sesi  3 : Memahami penyesuaian harga dalam retail</h3>
            <p>Akan menjabarkan konsep penyesuaian harga yang ada di retail, yaitu
            <ol>
              <li>Markdown</li>
              <li>Kupon</li>
              <li>Rabat</li>
              <li>Harga Bundle</li>
              <li>Harga Unit Pengganda</li>
              <li>Harga variable</li>
            </ol>
            </p>

            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
