import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-0 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-3 mb-0`}
      // p {
      //   ${tw`mt-0 inline leading-normal`}
      // }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "RB-008 Basic Functional Odoo Training Perusahaan Dagang" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2022</p>
            <h2>Tujuan :</h2>
            <p>
            <ol>
              <li>Odoo Sales Module for General Trading</li>
              <li>Odoo Purchasing Module </li>
              <li>Odoo Inventory Module </li>
              <li>Odoo Accounting Module</li>
              <li>Odoo Manufacturing Module</li>
            </ol>
            </p>
            <h2>Durasi : </h2>2 hari
            <h2>Requirement : </h2><p>Paham atau Pernah terjun ke Bisnis Perusahaan Dagang.</p>
            <h2>Investasi :</h2><p>Rp 1.500.000</p>

            <h3>Sesi  1 : Basic Accounting Menggunakan Google Sheet</h3>
            <p>
            <ol>
              <li>Pengantar Akuntansi</li>
              <li>Setup Modal Awal Usaha</li>
              <li>Jurnal</li>
            </ol>
            </p>
            {/* <p>
            Sesi pertama akan memberikan pemahaman ke peserta Training mengenai status Promosi sebagai salah satu strategi dalam bisnis retail yang tidak bisa dipisahkan dengan strategi lainnya yang harus bisa bersinergi untuk mendapatkan tujuan atau goal yang ditetapkan
            </p> */}
            <h3>Sesi  2 : Basic Odoo Operasional menggunakan Studi kasus hari 1</h3>
            <p>
            <ol>
              <li>Sales Order</li>
              <li>Input Expense </li>
              <li>Laporan Keuangan Menggunakan Odoo</li>
            </ol>
            </p>

            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
