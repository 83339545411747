import namor from 'namor'

const range = len => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

const newPerson = () => {
  const statusChance = Math.random()
  return {
    firstName: namor.generate({ words: 1, numbers: 0 }),
    lastName: namor.generate({ words: 1, numbers: 0 }),
    Mei: Math.floor(Math.random() * 50),
    Juni: Math.floor(Math.random() * 50),
    Juli: Math.floor(Math.random() * 50),

    status:
      statusChance > 0.66
        ? 'relationship'
        : statusChance > 0.33
        ? 'complicated'
        : 'single',
  }
}

// export default function makeData(...lens) {
//   const makeDataLevel = (depth = 0) => {
//     const len = lens[depth]
//     return range(len).map(d => {
//       return {[
//         {
//           classname:'Basic Retail',
//           mei : '17,30',
//           jun : '8,20,30',
//           jul : '11,20'
//         }        
//       ]
//     })
//   }

//   return makeDataLevel()
// }