import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";

const Container = tw.div`relative mt-0`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const SubmitButton = tw(NavLink)`
  lg:mx-0 mt-12
  px-4 py-4 rounded bg-purple-800 text-white
  hocus:bg-purple-800 hocus:text-white focus:shadow-outline
  border-b-0
`;

export const PrimaryLinkContactUs = tw(NavLink)`
  lg:mx-0
  px-8 py-4 rounded bg-purple-800 text-white
  hocus:bg-purple-800 hocus:text-white focus:shadow-outline
  border-b-0
`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

// const SubmitButton = tw(PrimaryButtonBase)`bg-purple-800 text-purple-800
// hocus:bg-purple-800 text-gray-200 inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = "Hubungi kami",
  heading = <>Silakan hubungi kami <span tw="text-purple-800">Jika ada pertanyaan</span><wbr/> lebih lanjut.</>,
  description = "Silakan lempar pertanyaan melalui email resmi kami",
  submitButtonText = "Email Kami",
  whatsappButtonText = "Whatsapp Kami",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  function sendMail() {
    var link = "mailto:me@example.com"
             + "?cc=myCCaddress@example.com"
             + "&subject=" + encodeURIComponent("This is my subject")
             + "&body=" + encodeURIComponent(document.getElementById('myText').value)
    ;
    
    window.location.href = link;
  }
  

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <SubmitButton href="mailto:sales@kapesolusi.work">{submitButtonText}</SubmitButton>{' '}
            <SubmitButton href="https://wa.me/62859106855300?text=Saya Tertarik Produk Anda">{whatsappButtonText}</SubmitButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      {/* <PrimaryLinkContactUs css={false && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6281299778588&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLinkContactUs> */}
    </Container>
  );
};
