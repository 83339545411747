import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import odoo17 from "images/odoo_apps17.png";
import sale1 from "images/sale2.png";
import so1 from "images/so-1.png";
import acct from "images/acct.png";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-green-500 text-white
  hocus:bg-red-600 hocus:text-white focus:shadow-outline
  border-b-0
`;


    // class="mb-4 mt-0 text-base font-light leading-relaxed"
    // ${tw`mt-0 leading-loose`}

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mb-4 mt-0 text-base font-light leading-relaxed`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  ol {
    ${tw`ml-3 list-disc list-outside`}
    li {
      ${tw`ml-3 mb-0 list-decimal`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }

  `;

export default ({ roundedHeaderButton = false,headingText = "Bagaimana Proses Customization di Odoo" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p >Last updated: Mei 1, 2020</p>
            <h2>Kenapa perlu Kustomisasi</h2>
            <br />
            <p>
            Ada beberapa alasan mengapa perusahaan memilih untuk melakukan kustomisasi pada platform perangkat lunak seperti Odoo. Berikut adalah beberapa alasan umum:             
            </p>
            {/* <p>Odoo Enterprise adalah versi berbayar dari perangkat lunak perusahaan sumber terbuka yang disebut Odoo. Odoo sendiri adalah suatu platform perangkat lunak yang menyediakan berbagai modul bisnis, seperti manajemen keuangan, penjualan, manufaktur, sumber daya manusia, dan banyak lagi. Platform ini dirancang untuk membantu perusahaan mengelola berbagai aspek operasional mereka.</p>
            <p>Berbeda dengan versi sumber terbuka (Odoo Community), Odoo Enterprise menawarkan sejumlah fitur tambahan dan dukungan yang lebih luas. Fitur-fitur tersebut dapat mencakup modul-modul yang lebih canggih, dukungan teknis yang lebih baik, integrasi pihak ketiga yang lebih kuat, dan keamanan yang ditingkatkan. Pengguna Odoo Enterprise biasanya membayar lisensi berlangganan untuk mendapatkan akses ke fitur-fitur premium ini.</p>
            <p>Odoo sendiri awalnya dikenal sebagai OpenERP (Enterprise Resource Planning) sebelum mengadopsi merek Odoo. Platform ini mencoba menyediakan solusi terintegrasi untuk kebutuhan manajemen bisnis, memungkinkan perusahaan untuk mengotomatiskan proses bisnis mereka dan meningkatkan efisiensi.</p> */}
            {/* <h2>Fitur odoo accounting</h2> */}
            <br />
            <ol>
              <li>Kepatuhan dan Persyaratan Bisnis Khusus <br />
              <p>
              Setiap perusahaan memiliki kebutuhan bisnis yang unik dan mungkin harus mematuhi peraturan atau standar industri tertentu. Kustomisasi Odoo memungkinkan perusahaan untuk menyesuaikan sistem sesuai dengan kebutuhan spesifik mereka.
              </p>
              </li>
              <li>Proses Bisnis yang Berbeda<br />
              <p>Setiap perusahaan memiliki proses bisnis yang berbeda-beda. Kustomisasi Odoo memungkinkan perusahaan untuk mengintegrasikan proses bisnis yang spesifik dan menghilangkan hambatan dalam aliran kerja mereka.</p>
              </li>
              <li>Pengalaman Pengguna yang Lebih Baik<br />
              <p>Kustomisasi dapat membantu meningkatkan antarmuka pengguna dan memberikan pengalaman yang lebih baik kepada pengguna akhir. Dengan menyesuaikan antarmuka dan alur kerja, perusahaan dapat memastikan bahwa sistem digunakan dengan lebih efisien oleh karyawan mereka.</p>
              </li>
              <li>Integrasi dengan Sistem yang Ada<br />
              <p>Perusahaan sering memiliki sistem lain yang mereka gunakan, dan kustomisasi Odoo dapat membantu dalam integrasi yang lebih baik dengan sistem-sistem tersebut. Ini membantu perusahaan untuk memaksimalkan kegunaan sistem yang sudah ada.
              </p>
              </li>
              <li>Keunggulan Kompetitif<br />
              <p>Dalam beberapa kasus, perusahaan mungkin ingin mendapatkan keunggulan kompetitif dengan menghadirkan fitur-fitur atau fungsionalitas yang tidak tersedia dalam versi standar Odoo. Kustomisasi dapat membantu menciptakan keunikan dan memberikan nilai tambah
              </p>
              </li>
              <li>Perubahan Kebutuhan Bisnis<br />
              <p>Bisnis terus berkembang dan berubah seiring waktu. Kustomisasi memungkinkan perusahaan untuk menyesuaikan Odoo dengan perubahan kebutuhan bisnis mereka tanpa harus beralih ke solusi baru
              {/* <img src={acct} width="800" height="393" /> */}
              </p>
              </li>
            </ol>
            <p>
            Namun, perlu diingat bahwa kustomisasi juga dapat membawa risiko, seperti biaya tambahan, kompleksitas pengelolaan, dan potensi kesulitan dalam pembaruan sistem. Oleh karena itu, perusahaan perlu mempertimbangkan keputusan ini dengan cermat dan merencanakan kustomisasi dengan bijaksana.
            </p>
            <p>Jika ingin lebih mengenal odoo silakan hubungi kami by whatsapp</p>

            {/* <h3>Sesi  2 : Memahami Pengertian retail </h3>
            <p>
            Memahami Fungsi dan jenis jenis perusahaan Retail            </p>
            <h3>Sesi  3 : Memahami matematika retail  </h3>
            <p>
            Akan menjabarkan konsep Matematika retail yang umum digunakan dalam bisnis retail             </p>


            <h2>Ingin daftar kelas ini :</h2>
            <p>Silakan hubungi nomor 081299778588 (Suryadi)</p> */}
            <br />
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`}href="https://api.whatsapp.com/send/?phone=6285781574709&Hallo,Driss&app_absent=0">Whatsapp</PrimaryLink>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
