import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import styled from "styled-components";

const Container = styled.div`
  ${tw`relative my-8 lg:my-10 bg-red-600 text-gray-100 -mx-8 px-8`}
  background-image: url("https://source.unsplash.com/KdeqA3aTnBY/1920x1280");
`;

// const Container = styled.div' ${tw(ContainerBase)`my-8 lg:my-10 bg-red-600 text-gray-100 -mx-8 px-8}
//   background-image: url("https://images.unsplash.com/photo-1578916171728-46686eac8d58?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
// `;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-red-500 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col text-red-500 sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default ({
  subheading = "",
  heading = "Retail Training Spesialis",
  description = "Bisnis retail mini market diprediksi akan meningkat dan berpotensi untuk berkembang sebagai lahan investasi, dan ada banyak alternatif untuk mengembangkan retail salah satunya adalah membuka atau upgrade mini market mandiri. Akan tetapi untuk mengembangkan mini market mandiri ada beberapa yang harus dipersiapkan dan yang terpenting adalah SDM",
  stats = [
    {
      key: "% Pertumbuhan Penjualan Retail",
      value: "12,9%",
    },
    {
      key: "% Kenaikan jumlah Mini Market",
      value: "32,1%",
    },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
